import React from 'react';
import FilterForm from './FilterForm';

export default function CustomerFilters({filterInfo}) {

  return (
    <>
      <FilterForm />
    </>
  );
}





